import {Button, Result} from 'antd';
import Global from '../../config/global';
import {Link} from 'react-router-dom';
import {FullContent} from '../content/full-content';
import loginUser from "../../store/loginUser";

export default function Error404(props) {
        return <FullContent>
            <Result
                status='404'
                title='404'
                subTitle='当前访问的页面不存在！'
                extra={<Button type='primary'>{loginUser.login ? <Link to={Global.HomePage}> 返回首页</Link> :
                    <Link to={Global.LoginPage}> 前往登录页</Link>}</Button>}
            />
        </FullContent>;
}

module.exports = {
    AppName: "杭州霜晨综合管理平台",
    AppSimpleName: "杭州霜晨综合管理平台",
    FooterText: "Ant Design ©2018 Created by Ant UED",
    HomePage: "/",
    UploadFileUrl: "https://img.hangzhoushuangchen.com/upload/files",
    LockPage: "/lock",
    CaptchaUrl: "/captcha",
    LoginPage: "/login",
    InitMenu: false,
    USERINFO_KEY: "website_userinfo",
    SECRET_KEY: "website_secret",
    LOCAL_URL_KEY: "website_local_url",
    BaseUrl: "https://admin.lsshenyan.com/apps",
    OldCrmUrl: "https://docs.xiaomifengb.com/crm-admin"
}

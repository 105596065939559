import React from "react";
import loginUser from "../store/loginUser";

const a_Z = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
];
const colors = [
    '#2f54eb',
    'rgb(246,179,7)',
    'rgb(80, 193, 233)',
    'rgb(169, 109, 243)',
    'rgb(245,97,58)',
    'rgb(103, 197, 12)',
    'rgb(80, 193, 233)',
    'rgb(110,58,120)',
];
const lightColors = [
    'green',
    'cyan',
    'blue',
    'geekblue',
    'purple',
    'magenta',
    'red',
    'volcano',
    'orange',
];

export function getColor(str) {
    if (!str) return colors[0];
    return colors[str.charCodeAt(0) % colors.length];
}

export function getLightColor(str) {
    if (!str) return lightColors[0];
    return lightColors[str.charCodeAt(0) % lightColors.length];
}

export function UUID() {
    let uuid = '';
    for (let i = 0; i < 20; i++) {
        let index = Math.floor(Math.random() * this.a_Z.length);
        uuid += a_Z[index];
    }
    return uuid;
}

export function NotNull(data: any): boolean {
    return data !== null && data !== undefined && data !== '';
}

export function IsNull(data: any): boolean {
    return data === null || data === undefined || data === '';
}

export function GetDef(data: any, def: any): boolean {
    return IsNull(data) ? def : data;
}

export function IsTrue(data: boolean): boolean {
    return data;
}

export function IsFalse(data: boolean): boolean {
    return data === false;
}

export function Copy(source: any, target: any): any {
    Object.keys(target).forEach(p => target[p] = source[p]);
    return target;
}

export function Exists(array: [], key, val) {
    for (let i in array) {
        if (array[i][key] === val) {
            return true;
        }
    }
    return false;
}

export function NotArrayExists(array: [], val) {
    for (let i in array) {
        if (array[i] === val) {
            return false;
        }
    }
    return true;
}

export function CopyArray(array: [], form, to) {
    const newArray = []
    for (let i = form; i < to; i++) {
        newArray.push(array[i])
    }
    return newArray;
}

export function isMobile(): boolean {
    return /(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)
}

export function isPC(): boolean {
    return !isMobile()
}

export function locationHref(href: string) {
    window.location.href = href
}

type options = {
    filter: [];
    columnMapping: {},
}

export function toPage(url) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const path = urlSearchParams.get("path");
    if (!loginUser.initPwd) {
        window.location.href = "/initPwd" + (NotNull(path) ? "?path=" + path : "");
    } else if (NotNull(path)) {
        window.location.href = path;
    } else {
        window.location.href = url;
    }
}

export function ScanDataList(formData, toData, options: options = {}) {
    const {filter = [], columnMapping = {}} = options;

    for (let i = 0; i < formData.length; i++) {
        const node = formData[i];
        const toNode = {};
        Object.keys(node).forEach(p => {
            if (p !== "children") {
                if (filter.indexOf(p) === -1) {
                    if (columnMapping[p]) {
                        toNode[columnMapping[p]] = node[p]
                    } else {
                        toNode[p] = node[p]
                    }

                }

            }
        })
        toNode.children = []
        toData[i] = toNode;
        if (node.children) {
            ScanDataList(node.children, toNode.children, options);
        }
    }
};

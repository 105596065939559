import {Link} from 'react-router-dom';
import React from "react";
import loginUser from '../../store/loginUser';

class AuthLinkProps {
    to: string;
    children: string;

}

export default class AuthLink extends React.Component<any, AuthLinkProps> {

    render() {
        const props = this.props;
        if (props.to === "#" || props.to === "" || props.to === undefined) {
            return <>{props.children}</>
        } else if (loginUser.hashMenuPath(props.to)) {
            return <Link to={props.to}>
                {this.props.children}
            </Link>;
        } else {
            return <Link to={`/403?path=${props.to}`}> {props.children}</Link>;
        }
    }
}


import "./style.less"
import {CSSProperties} from "react";
import {Card} from "antd";

class FullContentProps {
    style: CSSProperties;
}


export function FullContent(props: FullContentProps) {
    const {children} = props;
    return    <div style={props.style} className={"content"}>
        {children}
    </div>;
}

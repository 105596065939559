import {Button, Result} from 'antd';
import Global from '../../config/global';
import {Link} from 'react-router-dom';
import {FullContent} from '../content/full-content';

export default function Error403(props) {
    const result = new URLSearchParams(window.location.search);
    return <FullContent>
        <Result
            status='403'
            title='403'
            subTitle={`【${result.get('path')}】没有访问当前页面的权限！`}
            extra={<>
                <Button type='primary'><Link to={Global.HomePage}> 前往首页</Link></Button>
                <Button type='primary' onClick={() => window.history.back()}> 返回上一页</Button>
            </>}
        />
    </FullContent>;
}
